import { useRef, useState } from 'react'
import { GQL_SECTIONBRAND } from '../../shared/Services/Graphql/Graphql'
import { useQuery } from '@apollo/client'
import { Link, useParams } from 'react-router-dom'

import { Sticker } from '../../widgets/sticker/sticker'
import MenuBrand from '../../widgets/brands/menu_brand/menu_brand'
import { Engine_Icon, List_Block_icon, Menu_Icon } from '../../../assets/svg'

import './item.scss'
import { PanelBlock } from '../../widgets/blocks/panel'
import { PanelSection } from '../../widgets/sections/panel'
import { PanelInformation } from '../../widgets/sections/panel/information'

import Blocks from '../../widgets/blocks/components/blocks'
import { SelectedModuleContext } from '../../shared/context/SelectedModule'


const Item = () => {
  const [isLeftMenu, thisLeftMenu] = useState(false)
  const [isSetInfo, thisSetInfo] = useState('SECTION')

  const [selected, setSelected] = useState<string>('');

  const contentItem = useRef<HTMLDivElement>(null)
  const params = useParams()

  let stylePositionSet: { marginLeft: string } | undefined;
  if (isSetInfo === "SECTION") {
    stylePositionSet = { marginLeft: '0px' };
  } else if (isSetInfo === "PAGE") {
    stylePositionSet = { marginLeft: 'calc(-360px - 2em)' };
  } else if (isSetInfo === "BLOCK") {
    stylePositionSet = { marginLeft: 'calc(-720px - 2em)' };
  }

  function Click(e: { target: HTMLElement; }) {
    const el = e.target.closest('.edit_block') as HTMLElement;
    if (el) {
      setSelected(el.dataset.id || '');
      el.closest('.module_content')?.querySelector(`.selected`)?.classList.remove('selected')
      el.classList.add('selected');
    }
  }

  const { error, loading, data } = useQuery(GQL_SECTIONBRAND, {
    variables: { brand: params.brand, section: params.section },
    fetchPolicy: 'cache-and-network',
  })

  function Hovered(e: { target: HTMLElement; }) {
    const el = e.target.closest('.edit_block') as HTMLElement;
    if (el) {
      el.closest('.module_content')?.querySelector(`.hovered`)?.classList.remove('hovered')
      el.classList.add('hovered');
    }

  }

  if (loading) return <p>Loading...</p>
  if (error) return <p>Alguma coisa está errada</p>

  return (

    <section className="items">
      <div className="container">
        <div className="page_itens">
          <div
            className="menu_brand"
            style={{
              marginLeft: isLeftMenu ? '0px' : '-360px',
            }}
          >
            <Sticker>
              <MenuBrand
                sections={data?.sectionBrand?.brand?.sections}
                idBrand={data?.sectionBrand?.brand?.id}
                brand={data?.sectionBrand?.brand?.slug}
              ></MenuBrand>
            </Sticker>
          </div>
          <div className="section_info">
            <div className="infos">
              <SelectedModuleContext selected={selected}>
                <div className="section_left">
                  <div className='section_set_menu'>
                    <div
                      className="icon"
                      onClick={() => (
                        thisLeftMenu(!isLeftMenu)
                      )}
                    >
                      <Menu_Icon />
                    </div>
                    <div
                      className="icon"
                      style={isSetInfo === 'PAGE' ? { background: '#a671ff' } : { background: '#fff' }}
                      onClick={() => (
                        isSetInfo === 'PAGE' ? thisSetInfo('SECTION') : thisSetInfo('PAGE')
                      )}
                    >
                      <List_Block_icon />
                    </div>
                    <div
                      className="icon"
                      style={isSetInfo === 'BLOCK' ? { background: '#a671ff' } : { background: '#fff' }}
                      onClick={() => (
                        isSetInfo === 'BLOCK' ? thisSetInfo('SECTION') : thisSetInfo('BLOCK')
                      )}
                    >
                      <Engine_Icon />
                    </div>
                    <div style={{
                      width: '100%',
                      padding: '0.5em 0'
                    }}>
                      <Link to="/brands">brands</Link> / <Link to={`/${data?.sectionBrand?.brand?.slug}/${data?.sectionBrand?.brand?.id}`}>{params.brand}</Link> / <span style={{ color: "#999" }}>{params.section}</span>
                    </div>
                  </div>
                  <div className='section_set'
                    style={stylePositionSet}
                  >
                    <div className="section_set_infos">
                      <PanelInformation infos={data?.sectionBrand} />
                    </div>
                    <div className="section_set_status"
                      onClick={(e: any) => {
                        Click(e)
                      }}
                    >
                      <PanelSection />
                    </div>
                    <div className="section_set_blocks">
                      <PanelBlock dataBlock={selected} />
                    </div>
                  </div>
                </div>
                <div className='section_right'>
                  <div ref={contentItem} className="module_content"
                    onClick={(e: any) => {
                      Click(e)
                    }}
                    onMouseOver={(e: any) => {
                      Hovered(e);
                    }}
                  >
                    <Blocks />
                  </div>
                </div>
              </SelectedModuleContext>
            </div>
          </div>
        </div>
      </div>
    </section >

  )
}

export default Item
