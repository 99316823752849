import { createRef, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import DeleteSection from './delete_section';
import { EditSection } from './edit_section';
import { ModelContext } from '../../../shared/Services/Graphql/querysContext';

const MenuItem = (props) => {
  const [edit, setEdit] = useState(false);
  const ptitle = createRef<HTMLParagraphElement>();
  const { model }: any = useContext(ModelContext)

  const editMode = (editMode) => {
    editMode ? setEdit(true) : setEdit(false);
  };

  return (
    <>
      <div
        className={`${props.fatherItem ? 'menu_model_content' : 'submenu_model_content'
          } ${edit ? 'edit_section' : ''} content_item`}
      >
        <Link
          to={
            edit
              ? ''
              : {
                pathname: `/model/${model.id}/${props.sectionData.id}/${props.sectionData?.modules[0]?.id}`,
              }
          }
        >
          <p
            className="menu_model_name"
            contentEditable={edit}
            ref={ptitle}
          >
            {props.sectionData.name}
          </p>
        </Link>
        <div
          className={`${edit ? 'edit_section' : ''
            } model_item_options`}
        >
          <DeleteSection
            id={props.sectionData.id}
            sectionParent={props.sectionParent}
            model={model.id}
          />
          <EditSection
            id={props.sectionData.id}
            sectionParent={props.sectionParent}
            ptitle={ptitle}
            edit={edit}
            editMode={editMode}
          />
        </div>
      </div>
    </>
  );
};

export default MenuItem;
