import React, { useState, useEffect, useContext } from "react";
import './styles.scss';
import { ContentModule } from "../module/content";
import { useMutation } from "@apollo/client";
import { GQL_MODULE, MUTATION_UPDATEMODULE } from "../../shared/Services/Graphql/Graphql";
import { useParams } from "react-router-dom";

const EditableContent = ({ content, className }) => {
  const { module } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const contentModule = ContentModule()

  const [updateModule] = useMutation(MUTATION_UPDATEMODULE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GQL_MODULE, variables: { id: module } },
    ],
  })

  const updateBlockContent = (blocks, blockId, newContent) => {
    return blocks.map(block => {
      if (block.id === blockId) {
        return { ...block, content: newContent };
      } else if (block.content && Array.isArray(block.content)) {
        return { ...block, content: updateBlockContent(block.content, blockId, newContent) };
      }
      return block;
    });
  };

  const handleBlur = (e) => {
    setIsEditing(false);
    const newContent = e.currentTarget.textContent || '';
    const idBlock = e.currentTarget.closest('.edit_block').dataset.id
    const moduleString = JSON.stringify(updateBlockContent(contentModule, idBlock, newContent))
    
    updateModule({
      variables: {
        id: module,
        data: {
          content: moduleString,
        },
      },
    })
      .then((res) => {
        console.log(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  };

  return (
    <div
      contentEditable={isEditing}
      className={`editable-content ${className}`}
      style={{ whiteSpace: 'pre-wrap', cursor: isEditing ? 'text' : 'pointer' }}
      onBlur={handleBlur}
      onClick={() => !isEditing && setIsEditing(true)}
      suppressContentEditableWarning={true}
    >
      {content}
    </div>
  );
};

export default EditableContent;