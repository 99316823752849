import React, { useState } from 'react'
import Loop_file from '../../../../pages/file/Loop_file'

import './styles.scss'
import UploadFile from '../../../../pages/file/Upload_file'
import { useParams } from 'react-router-dom'
import { GQL_MODULE, MUTATION_UPDATEMODULE } from '../../../../shared/Services/Graphql/Graphql'
import { useMutation } from '@apollo/client'
import blockData from './block.json'
import { ContentModule } from '../../../module/content'

export const ModalFiles = ({ setActive }) => {
    const { module } = useParams();
    const contentModule = ContentModule();
    const [selectFile, setSelectFile] = useState({
        id: '', url: '', name: '', width: 0, height: 0
    })

    const [updateModule] = useMutation(MUTATION_UPDATEMODULE, {
        awaitRefetchQueries: true,
        refetchQueries: [
            { query: GQL_MODULE, variables: { id: module } },
        ],
    })

    const updateBlockAttribute = (blocks, blockId) => {
        return blocks.map(block => {
            if (block.id === blockId) {
                return { ...block, attributes: { ...block.attributes, src: selectFile.url, alt: selectFile.name } };
            } else if (block.content && Array.isArray(block.content)) {
                return { ...block, content: updateBlockAttribute(block.content, blockId) };
            }
            return block;
        });
    };

    const handleImageSelect = (e) => {
        const id = e.target.closest('.edit_block')?.dataset.id;
        const newBlock = updateBlockAttribute(contentModule, id)


        updateModule({
            variables: {
                id: module,
                data: {
                    content: JSON.stringify(newBlock),
                },
            },
        })
            .then((res) => {
                console.log(res.data)
                setActive(false)
            })
            .catch((err) => {
                console.log(err)
            })
    };

    return (
        <div className="modal_files">
            <div className="modal_inner">
                <div className='modal_header'>
                    <h3>Arquivos</h3>
                    <button onClick={() => setActive(false)}>Close</button>
                </div>
                <div className='modal_body'>
                    <div className='modal_upload'>
                        <UploadFile />
                    </div>
                    <div className='modal_loop'>
                        <Loop_file selectFile={setSelectFile} select={selectFile.id} />
                    </div>
                </div>
                <div className='modal_footer'>
                    <button onClick={(e) => handleImageSelect(e)}>Select</button>
                </div>
            </div>
        </div>
    )
}
