import { useState } from 'react'
import { imagemScale } from '../../shared/tools/image_scale'
import { useMutation } from '@apollo/client'
import {
  GQL_FILES,
  MUTATION_CREATEFILE,
} from '../../shared/Services/Graphql/Graphql'
import { Upload_icon } from '../../../assets/svg'

function UploadFile() {
  const [files] = useMutation(MUTATION_CREATEFILE, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GQL_FILES }],
  })

  const uploadImage = async (e: FileList) => {
    const filesArray = Array.from(e); // Converte FileList em um array

    for (const file of filesArray) {
        const formData = new FormData();
        formData.append('files', file); // Adiciona um único arquivo ao FormData

        const xhr = new XMLHttpRequest();
        xhr.responseType = 'json';

        xhr.onload = async () => {
            if (xhr.status === 200) {
                console.log(xhr.response);
                const responseFiles = xhr.response;

                for (let file of responseFiles) {
                    file['url'] = `https://files.universodamarca.com.br/${file.path}`;
                    
                    // Supondo que imagemScale retorne um objeto com propriedades width e height
                    await imagemScale(file.path).then((value: any) => {
                        file['width'] = value.width;
                        file['height'] = value.height;
                    });

                    await files({
                        variables: {
                            data: {
                                alt: file.originalname,
                                height: file.height,
                                name: file.originalname,
                                size: file.size,
                                type: file.mimetype,
                                url: file.url,
                                width: file.width,
                            },
                        },
                    })
                    .then((res) => {
                        console.log(res);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                }
            }
        };

        xhr.onerror = () => {
            console.error('Erro ao enviar o arquivo:', file.name);
        };

        xhr.open('POST', 'https://files.universodamarca.com.br/files');
        xhr.send(formData);

        // Opcional: Adicione um pequeno delay entre os envios, se necessário
        await new Promise((resolve) => setTimeout(resolve, 1000));
    }
};

  return (
    <form className="upload_form">
      <div className='icon'><Upload_icon /></div>
      <label htmlFor="file">
        <span>Clique e selecione uma imagem.</span>
        {/* {label ? "" : "Arraste ou <span>selecione</span> uma imagem."} */}
      </label>
      <input
        type="file"
        className="file"
        id="file"
        name="file"
        multiple
        onChange={(e) => uploadImage((e.target as any).files)}
      />
    </form>
  )
}

export default UploadFile
