import { useRef, useState } from 'react'
import { GQL_SECTION } from '../../shared/Services/Graphql/Graphql'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { Sticker } from '../../widgets/sticker/sticker'
import { MenuModel } from '../../widgets/models/menu_model/menu_model'
import { Engine_Icon, List_Block_icon, Menu_Icon, Models_Settings_icon } from '../../../assets/svg'

import { PanelBlock } from '../../widgets/blocks/panel'
import { PanelSection } from '../../widgets/sections/panel'
import { PanelInformationModel } from '../../widgets/models/information_models'

import Blocks from '../../widgets/blocks/components/blocks'
import { SelectedModuleContext } from '../../shared/context/SelectedModule'

import './style.scss'
import { ModelProvider } from '../../shared/Services/Graphql/querysContext'
import { ConfigModel } from '../../widgets/models/config_model'

export const Model = () => {
    const [isLeftMenu, thisLeftMenu] = useState(false)
    const [isSetInfo, thisSetInfo] = useState('SECTION')
    const [selected, setSelected] = useState<string>('');

    const contentItem = useRef<HTMLDivElement>(null)
    const { model, section } = useParams<string>()

    let stylePositionSet: { marginLeft: string } | undefined;
    if (isSetInfo === "SECTION") {
        stylePositionSet = { marginLeft: '0px' };
    } else if (isSetInfo === "PAGE") {
        stylePositionSet = { marginLeft: 'calc(-360px - 1em)' };
    } else if (isSetInfo === "BLOCK") {
        stylePositionSet = { marginLeft: 'calc(-720px - 1em)' };
    } else if (isSetInfo === "MODEL") {
        stylePositionSet = { marginLeft: 'calc(-1080px - 1em)' };
    }

    function Click(e: { target: HTMLElement; }) {
        const el = e.target.closest('.edit_block') as HTMLElement;
        if (el) {
            setSelected(el.dataset.id || '');
            el.closest('.module_content')?.querySelector(`.selected`)?.classList.remove('selected')
            el.classList.add('selected');
        }
    }

    const { error, loading, data } = useQuery(GQL_SECTION, {
        variables: { id: section },
        fetchPolicy: 'cache-and-network',
    })

    function Hovered(e: { target: HTMLElement; }) {
        const el = e.target.closest('.edit_block') as HTMLElement;
        if (el) {
            el.closest('.module_content')?.querySelector(`.hovered`)?.classList.remove('hovered')
            el.classList.add('hovered');
        }
    }

    if (loading) return <p>Loading...</p>
    if (error) return <p>Alguma coisa está errada</p>

    return (
        <ModelProvider idModel={model}>
            <section className="items">
                <div className="container">
                    <div className="page_itens">
                        <div
                            className="menu_brand"
                            style={{
                                marginLeft: isLeftMenu ? '0px' : '-360px',
                            }}
                        >
                            <Sticker>
                                <MenuModel />
                            </Sticker>
                        </div>
                        <div className="section_info">
                            <div className="infos">
                                <SelectedModuleContext selected={selected}>
                                    <div className="section_left">
                                        <div className='section_set_menu'>
                                            <div
                                                className="icon"
                                                onClick={() => (
                                                    thisLeftMenu(!isLeftMenu)
                                                )}
                                            >
                                                <Menu_Icon />
                                            </div>
                                            <div
                                                className="icon"
                                                style={isSetInfo === 'PAGE' ? { background: '#a671ff' } : { background: '#fff' }}
                                                onClick={() => (
                                                    isSetInfo === 'PAGE' ? thisSetInfo('SECTION') : thisSetInfo('PAGE')
                                                )}
                                            >
                                                <List_Block_icon color={isSetInfo === 'PAGE' ? "#fff" : '#000'} />
                                            </div>
                                            <div
                                                className="icon"
                                                style={isSetInfo === 'BLOCK' ? { background: '#a671ff' } : { background: '#fff' }}
                                                onClick={() => (
                                                    isSetInfo === 'BLOCK' ? thisSetInfo('SECTION') : thisSetInfo('BLOCK')
                                                )}
                                            >
                                                <Engine_Icon color={isSetInfo === 'BLOCK' ? "#fff" : '#000'} />
                                            </div>
                                            <div
                                                className="icon"
                                                style={{
                                                    background: isSetInfo === 'MODEL' ? '#a671ff' : '#fff',
                                                    marginLeft: '1em',
                                                }}
                                                onClick={() => (
                                                    isSetInfo === 'MODEL' ? thisSetInfo('SECTION') : thisSetInfo('MODEL')
                                                )}
                                            >
                                                <Models_Settings_icon color={isSetInfo === 'MODEL' ? "#fff" : '#000'} />
                                            </div>
                                        </div>
                                        <div className='section_set'
                                            style={stylePositionSet}
                                        >
                                            <div className="section_set_infos">
                                                <PanelInformationModel infos={data?.section} />
                                            </div>
                                            <div className="section_set_status"
                                                onClick={(e: any) => {
                                                    Click(e)
                                                }}
                                            >
                                                <PanelSection />
                                            </div>
                                            <div className="section_set_blocks">
                                                <PanelBlock dataBlock={selected} />
                                            </div>
                                            <div className="section_set_settings">
                                                <ConfigModel />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='section_right'>
                                        <div ref={contentItem} className="module_content"
                                            onClick={(e: any) => {
                                                Click(e)
                                            }}
                                            onMouseOver={(e: any) => {
                                                Hovered(e);
                                            }}
                                        >
                                            <Blocks />
                                        </div>
                                    </div>
                                </SelectedModuleContext>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </ModelProvider>
    )
}
