import { gql } from "@apollo/client";

export const MUTATION_LOGINUSER = gql`
  mutation LoginUser($data: LoginUserInput) {
    loginUser(data: $data) {
      token
    }
  }
`;

export const MUTATION_CREATENEWUSER = gql`
  mutation createNewUser($data: CreateUserInput!) {
    createUser(data: $data) {
      email
    }
  }
`;

export const MUTATION_FILLUSER = gql`
  mutation fillNewUser($data: FillUserInput) {
    fillUser(data: $data) {
      id
    }
  }
`;
export const MUTATION_UPDATEUSER = gql`
  mutation updateUser($id: ID!, $data: UpdateUserInput) {
    updateUser(id: $id, data: $data) {
      id
    }
  }
`;
export const MUTATION_REQUESTNEWCREATEUSERTOKEN = gql`
  mutation newRequestNewCreateUserToken($email: String!) {
    requestNewCreateUserToken(email: $email) {
      token
    }
  }
`;

export const MUTATION_FORGOTPASSWORD = gql`
  mutation forgotUserPassword($email: String!) {
    forgotPassword(email: $email) {
      email
    }
  }
`;

export const MUTATION_CHANGEPASSWORD = gql`
  mutation changeUserPassword($data: ChangePasswordInput) {
    changePassword(data: $data) {
      id
    }
  }
`;

export const GQL_USER = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      name
      active
      admin
      brands {
        id
        name
      }
      email
      phone
      profilePicture
      userName
      token
    }
  }
`;

export const GQL_USERS = gql`
  query Users {
    users {
      id
      name
      userName
      email
      phone
      active
      brands {
        id
      }
      created_at
      update_at
    }
  }
`;

export const GQL_USERSBRAND = gql`
  query UsersBrand($id: ID!) {
    usersBrand(brand: $id) {
      id
      name
      userName
      email
      phone
      active
      brands {
        id
      }
      created_at
      update_at
    }
  }
`;

export const GQL_BRANDS = gql`
  query Brands($sort: BrandSortInput, $skip: Int, $take: Int) {
    brands(sort: $sort, skip: $skip, take: $take) {
      id
      name
      slug
      settings {
        logoLogin {
          id
          type
          file {
            id
            name
            url
          }
        }
        logoHightlight {
          id
          type
          file {
            id
            name
            url
          }
        }
        downloadImage {
          id
          url
        }
        colorHightlight
        colorPrimaryLogin
        colorSecondaryLogin
      }
      logos {
        type
        file {
          id
          name
          url
        }
      }
      files {
        type
        file {
          id
          name
          url
        }
      }
      sections {
        id
      }
      holding {
        id
      }
      created_by {
        id
      }
      update_by {
        id
      }
    }
  }
`;

export const GQL_BRAND = gql`
  query Brand($id: ID!) {
    brand(id: $id) {
      id
      name
      slug
      settings {
        logoLogin {
          id
          type
          file {
            id
            name
            url
          }
        }
        logoHightlight {
          id
          type
          file {
            id
            name
            url
          }
        }
        downloadImage {
          id
          url
        }
        colorHightlight
        colorPrimaryLogin
        colorSecondaryLogin
      }
      logos {
        id
        type
        file {
          id
          name
          url
        }
      }
      files {
        id
        type
        file {
          id
          name
          url
        }
      }
      sections {
        id
        name
        slug
        subsections {
          id
          name
          slug
        }
        modules {
          id
          name
        }
        downloads {
          id
        }
      }
      highlights {
        id
        name
        slug
        cover {
          id
        }
      }
      holding {
        id
      }
      created_by {
        id
      }
      update_by {
        id
      }
    }
  }
`;

export const GQL_BRANDSLUG = gql`
  query Brand($id: ID!) {
    brand(id: $id) {
      id
      name
      slug
      settings {
        logoLogin {
          id
          type
          file {
            id
            name
            url
          }
        }
        logoHightlight {
          id
          type
          file {
            id
            name
            url
          }
        }
        downloadImage {
          id
          url
        }
        colorHightlight
        colorPrimaryLogin
        colorSecondaryLogin
      }
      logos {
        id
        type
        file {
          id
          name
          url
        }
      }
      files {
        id
        type
        file {
          id
          name
          url
        }
      }
      sections {
        id
        name
        slug
        subsections {
          id
          name
          slug
        }
        modules {
          id
          name
        }
        downloads {
          id
        }
      }
      highlights {
        id
        name
        slug
        cover {
          id
        }
      }
      holding {
        id
      }
      created_by {
        id
      }
      update_by {
        id
      }
    }
  }
`;

export const MUTATION_DELETEUSER = gql`
  mutation removeUser($id: ID!) {
    removeUser(id: $id)
  }
`;

export const MUTATION_CREATENEWBRAND = gql`
  mutation createBrand($data: BrandInput) {
    createBrand(data: $data) {
      id
      name
    }
  }
`;

export const MUTATION_UPDATEBRAND = gql`
  mutation updateBrand($id: ID!, $data: BrandInput) {
    updateBrand(id: $id, data: $data) {
      id
    }
  }
`;

export const MUTATION_CREATELOGOBRAND = gql`
  mutation createLogoBrand($id: ID!, $data: FileInput) {
    createLogoBrand(id: $id, data: $data) {
      id
    }
  }
`;

export const MUTATION_UPDATELOGOBRAND = gql`
  mutation updateLogoBrand($id: ID!, $data: FileInput) {
    updateLogoBrand(id: $id, data: $data) {
      type
    }
  }
`;

export const MUTATION_DELETELOGOBRAND = gql`
  mutation removeLogoBrand($id: ID!) {
    removeLogoBrand(id: $id)
  }
`;

export const MUTATION_CREATEFILEBRAND = gql`
  mutation createFileBrand($id: ID!, $data: FileInput) {
    createFileBrand(id: $id, data: $data) {
      id
    }
  }
`;

export const MUTATION_UPDATEFILEBRAND = gql`
  mutation updateFileBrand($id: ID!, $data: FileInput) {
    updateFileBrand(id: $id, data: $data) {
      type
    }
  }
`;

export const MUTATION_DELETEFILEBRAND = gql`
  mutation removeFileBrand($id: ID!) {
    removeFileBrand(id: $id)
  }
`;

export const MUTATION_UPDATESETTINGSBRAND = gql`
  mutation UpdateSettingsBrand($id: ID!, $data: SettingsBrandInput) {
    updateSettingsBrand(id: $id, data: $data) {
      id
    }
  }
`;

export const MUTATION_PUSHBRANDTOUSER = gql`
  mutation pushBrandToUser($id: ID!, $data: PushBrandToUserInput) {
    pushBrandToUser(id: $id, data: $data) {
      id
    }
  }
`;

export const MUTATION_REMOVEBRANDTOUSER = gql`
  mutation RemoveBrandToUser($id: ID!, $idBrand: ID) {
    removeBrandToUser(id: $id, idBrand: $idBrand) {
      id
      brands {
        id
      }
    }
  }
`;

export const MUTATION_DELETEBRAND = gql`
  mutation removeBrand($id: ID!) {
    removeBrand(id: $id)
  }
`;

export const GQL_SECTION = gql`
  query Section($id: ID!) {
    section(id: $id) {
      id
      name
      order
      slug
      modules {
        id
        name
        content
      }
      subsections {
        id
        name
        slug
        modules {
          id
        }
      }
      cover {
        id
        url
      }
      exhibition
      downloads {
        id
        name
        link
        file {
          id
        }
      }
      brand {
        id
        sections {
          id
          name
          slug
          modules {
            id
          }
          downloads {
            id
          }
        }
      }
      created_by {
        id
      }
      update_by {
        id
      }
      created_at
      update_at
    }
  }
`;

export const GQL_SECTIONBRAND = gql`
  query SectionBrand($brand: String!, $section: String!) {
    sectionBrand(brand: $brand, section: $section) {
      id
      name
      order
      slug
      modules {
        id
        name
        content
      }
      subsections {
        id
        name
        slug
        cover {
          id
          url
          name
          alt
        }
        modules {
          id
        }
      }
      downloads {
        id
        name
        link
        file {
          id
        }
      }
      cover {
        id
      }
      exhibition
      downloads {
        name
        file {
          id
        }
      }
      brand {
        id
        slug
        sections {
          id
          name
          slug
          modules {
            id
          }
        }
      }
      created_by {
        id
      }
      update_by {
        id
      }
      created_at
      update_at
    }
  }
`;

export const MUTATION_DELETESECTION = gql`
  mutation removeSection($id: ID!) {
    removeSection(id: $id)
  }
`;

export const MUTATION_CREATESECTION = gql`
  mutation createSection($idBrand: ID!, $data: SectionInput!) {
    createSection(idBrand: $idBrand, data: $data) {
      id
      name
    }
  }
`;

export const MUTATION_CREATESUBSECTION = gql`
  mutation createSubsection($id: ID!, $idBrand: ID!, $data: SectionInput!) {
    createSubsection(id: $id, idBrand: $idBrand, data: $data) {
      id
      name
      subsections {
        id
        name
      }
    }
  }
`;

export const MUTATION_CREATESECTIONMODEL = gql`
  mutation createSectionModel($model: ID!, $data: SectionInput!) {
    createSectionModel(model: $model, data: $data) {
      id
      name
    }
  }
`;

export const MUTATION_CREATESUBSECTIONMODEL = gql`
  mutation createSubsectionModel($id: ID!, $data: SectionInput!) {
    createSubsectionModel(id: $id, data: $data) {
      id
      name
      subsections {
        id
        name
      }
    }
  }
`;

export const MUTATION_UPDATESECTION = gql`
  mutation updateSection($id: ID!, $data: SectionInput!) {
    updateSection(id: $id, data: $data) {
      id
      name
    }
  }
`;

export const MUTATION_CREATEDOWNLOAD = gql`
  mutation createDownload($id: ID!, $data: DownloadInput) {
    createDownload(id: $id, data: $data) {
      id
    }
  }
`;

export const MUTATION_UPDATEDOWNLOAD = gql`
  mutation updateDownload($id: ID!, $data: DownloadInput) {
    updateDownload(id: $id, data: $data) {
      id
    }
  }
`;
export const MUTATION_DELETEDOWNLOAD = gql`
  mutation removeDownload($id: ID!) {
    removeDownload(id: $id)
  }
`;

export const GQL_MODULE = gql`
  query Module($id: ID!) {
    module(id: $id) {
      id
      order
      content
      name
      description
      created_at
      created_by {
        id
      }
      updated_at
      updated_by {
        id
      }
    }
  }
`;

export const GQL_MODULES = gql`
  query Modules {
    modules {
      modules {
        id
        order
        content
        name
        description
        created_at
        created_by {
          id
        }
        update_at
        update_by {
          id
        }
      }
    }
  }
`;

export const MUTATION_CREATEMODULE = gql`
  mutation createModule($idSection: ID, $data: ModuleInput!) {
    createModule(idSection: $idSection, data: $data) {
      id
    }
  }
`;

export const MUTATION_UPDATEMODULE = gql`
  mutation updateModule($id: ID, $data: ModuleInput!) {
    updateModule(id: $id, data: $data) {
      id
      content
    }
  }
`;

export const MUTATION_DELETEMODULE = gql`
  mutation removeModule($id: ID!) {
    removeModule(id: $id)
  }
`;

export const GQL_FILE = gql`
  query File($id: ID!) {
    file(id: $id) {
      id
      type
      name
      url
      alt
      width
      height
      size
      created_by {
        id
      }
      update_by {
        id
      }
      created_at
      update_at
    }
  }
`;

export const GQL_FILES = gql`
  query {
    files {
      id
      type
      name
      url
      alt
      width
      height
      size
      created_by {
        id
      }
      update_by {
        id
      }
      created_at
      update_at
    }
  }
`;

export const MUTATION_CREATEFILE = gql`
  mutation createFile($data: FileInput) {
    createFile(data: $data) {
      alt
      height
      id
      name
      size
      type
      url
    }
  }
`;
export const MUTATION_DELETEFILE = gql`
  mutation removeFile($id: ID!) {
    removeFile(id: $id)
  }
`;
