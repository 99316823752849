import React from 'react'
import Types from "../../components/types";
import { ContentModule } from "../../../module/content";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  GQL_MODULE,
  MUTATION_UPDATEMODULE,
} from "../../../../shared/Services/Graphql/Graphql";

import "./index.scss";
import { propDefinitions } from "../panelPropDefinition";
import InputText from "../inputsPanel";

export const Style = ({ styles, type, idblock }) => {
  const { module } = useParams();

  const styleType = type && Object.keys(Types[type]?.style.value);

  const dataModule = ContentModule();

  const [UpdateModule] = useMutation(MUTATION_UPDATEMODULE, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GQL_MODULE, variables: { id: module } }],
  });

  function updateSection(data: any) {
    UpdateModule({
      variables: {
        id: module,
        data: {
          content: JSON.stringify(data),
        },
      },
    })
      .then(res => {
        console.log(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const handleStyleChange = (value: any, type: any) => {
    function findElementById(obj, id) {
      if (obj.id === id) {
        return [];
      }
      if (Array.isArray(obj.content)) {
        for (let i = 0; i < obj.content.length; i++) {
          const result = findElementById(obj.content[i], id);
          if (result) {
            return ["content", i, ...result];
          }
        }
      }
      for (const key in obj) {
        if (
          obj.hasOwnProperty(key) &&
          typeof obj[key] === "object" &&
          obj[key] !== null
        ) {
          const result = findElementById(obj[key], id);
          if (result) {
            return [key, ...result];
          }
        }
      }
      return null;
    }

    const path = findElementById(dataModule[0], idblock);
    const newPath =
      path && path.join(".").replace(/\.\d+/g, match => `[${match.slice(1)}]`);

    const targetObject = newPath
      ? eval(`dataModule[0].${newPath}`)
      : dataModule.find(m => m.id === idblock);

    if (idblock && type) {
      targetObject.style[type] = value;
    }

    updateSection(dataModule);
  };

  return (
    <div className="style_edit">
      {styleType &&
        styleType.map((i, index) => (
          <div
            className="style_item"
            key={`style-${i}-${index}`}
          >
            <label htmlFor={idblock}>{propDefinitions[i]?.label ?? i}</label>
            <InputText
              id={idblock}
              value={styles[i]}
              type={i}
              placeholder={`Enter ${i}`}
              onBlur={handleStyleChange}
              readOnly={false}
            />
          </div>
        ))}
    </div>
  );
};
