import { useState } from "react";
import { Image_Add_icon, Image_icon } from "../../../../../assets/svg";
import { ModalFiles } from "./modal_files";

const Image = ({ id, attributes, content, className, style }) => {
    const [activeModal, setActiveModal] = useState(false)

    return (
        <div className="edit_figure">
            {attributes.src ?
                (
                    <figure className={className} style={style}>
                        <img
                            src={attributes.src}
                            alt={attributes.name}
                            style={{
                                height: attributes.imgHeight,
                                objectFit: attributes.objectFit
                            }} />
                    </figure>
                ) :
                (<div className="select" onClick={() => setActiveModal(!activeModal)}>
                    <Image_icon width="48px" />
                    <p>Selecionar arquivo</p>
                </div>)
            }
            <div className="change_figure" onClick={() => setActiveModal(!activeModal)}>
                <Image_Add_icon width="24px" />
            </div>
            {activeModal && <ModalFiles setActive={setActiveModal} />}
        </div>
    );
}

export default Image;