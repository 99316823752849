import { useMutation } from '@apollo/client'
import {
  GQL_FILES,
  MUTATION_DELETEFILE,
} from '../../shared/Services/Graphql/Graphql'
import { Delete_icon } from '../../../assets/svg'

export default function Delete_file(props: any) {
  const { url, id } = props

  const [file] = useMutation(MUTATION_DELETEFILE, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GQL_FILES }],
  })

  function delete_file() {
    var xhr = new XMLHttpRequest()
    xhr.responseType = 'json'
    xhr.onload = () => {
      xhr.status === 200 &&
        (async () => {
          await file({
            variables: {
              id: id,
            },
          })
            .then((res) => {
              console.log(res)
            })
            .catch((err) => {
              console.log(err)
            })
        })()
    }

    xhr.open('DELETE', url)
    xhr.send(null)
  }

  return (
    <div className="delete" onClick={() => delete_file()}>
      <Delete_icon />
    </div>
  )
}
