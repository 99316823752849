import { Error_Icon } from "../../../assets/svg";
import "./ErrorModal.scss";

interface Props {
  message: any;
  open: any;
  onClose: any;
}
const ErrorModal = ({ message, open, onClose }: Props) => {
 

  console.log(message, open, onClose)

  return (
    <>
      <div className="modal">
        <div className="modal_body">
        <div className="error_icon">{Error_Icon}</div>
          <div>
            <h2>{message}</h2>
          </div>
          <button onClick={onClose}>OK</button>
        </div>
        <div className="modal_back" onClick={onClose}></div>
      </div>
    </>
  );
};

export default ErrorModal;
