import React, { useState } from "react";
import { Image_icon } from "../../../../assets/svg";
import { ModalFilesBrand } from "../modal_files_brand";
import { useMutation } from "@apollo/client";
import { GQL_BRAND, MUTATION_CREATEFILEBRAND } from "../../../shared/Services/Graphql/Graphql";
import { DeleteFile } from "./delete";
import { UpdateFileImage } from "./updateImage";
import { UpdateFileName } from "./updateName";

// Defina uma interface para o tipo de imagem definida
interface DefinedImageType {
    id: string;
    url: string;
    name: string;
    width?: number;
    height?: number;
}

export const Files = ({ idBrand, files }) => {
    const [add, setAdd] = useState(false);
    const [selectedType, setSelectedType] = useState('');
    const [otherType, setOtherType] = useState('');
    const [activeModal, setActiveModal] = useState(false);
    const [definedImage, setDefinedImage] = useState<DefinedImageType | null>(null);

    const [createfileBrand] = useMutation(MUTATION_CREATEFILEBRAND, {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GQL_BRAND, variables: { id: idBrand } }],
    });

    const selectBrand = () => {
        if (!definedImage || !selectedType || (selectedType === 'OTHERS' && !otherType)) {
            alert('Por favor, selecione um tipo e uma imagem para o file.');
            return;
        }
        createfileBrand({
            variables: {
                id: idBrand,
                data: {
                    type: selectedType === "OTHERS" ? otherType : selectedType,
                    file: definedImage.id,
                }
            },
        }).then(() => {
            setAdd(false);
            setActiveModal(false);
            setDefinedImage(null);
        }).catch((error) => {
            console.error("Failed to update brand files", error);
        });
    };

    const allTypes = ['BACKGROUND', 'COVER', 'OTHERS'];
    const availableTypes = allTypes.filter(type => !files.some(file => file.type === type));

    return (
        <div className='files_config'>
            <h3 className='title_section'>files</h3>
            <div className='files_list'>
                {files.map(file => (
                    <div className='file_item' key={file.id}>
                        <div className="file_options">
                            <UpdateFileName file={file} idBrand={idBrand} />
                            <DeleteFile file={file} idBrand={idBrand} />
                        </div>
                        <UpdateFileImage file={file} idBrand={idBrand} />
                    </div>
                ))}

                <div className='add_file'>
                    {!add ? (
                        <div className="click_action_file" onClick={() => setAdd(true)}>
                            <div className='add_file_icon'>+</div>
                            <p>Adicionar file</p>
                        </div>
                    ) : (
                        <div className="form_action_file" >
                            <div className="form_action_image" onClick={() => setActiveModal(true)}>
                                {definedImage ? (
                                    <img src={definedImage.url} alt={definedImage.name} />
                                ) : (
                                    <div className="select">
                                        <Image_icon width="48px" />
                                        <p>Selecionar arquivo</p>
                                    </div>
                                )}
                            </div>
                            {activeModal && (
                                <ModalFilesBrand
                                    setActiveModal={setActiveModal}
                                    setDefinedImage={setDefinedImage}
                                    title={`file - ${selectedType}`} />
                            )}
                            <div className="form_action_type">
                                <select name="type" id="type" value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
                                    <option value="">Selecione um tipo</option>
                                    {availableTypes.map(type => (
                                        <option key={type} value={type}>{type}</option>
                                    ))}
                                </select>
                                {selectedType === 'OTHERS' && (
                                    <input
                                        type="text"
                                        name="customType"
                                        id="customType"
                                        placeholder="Adicione um ID personalizado"
                                        value={otherType}
                                        onChange={(e) => setOtherType(e.target.value.toUpperCase())}
                                    />
                                )}
                            </div>
                            <div className="form_action_buttons">
                                <div className="add" onClick={selectBrand}>
                                    Adicionar
                                </div>
                                <div className="Cancelar" onClick={() => setAdd(false)}>
                                    Cancelar
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
