import { useQuery } from '@apollo/client'
import React from 'react'
import { GQL_FILE } from '../../../../shared/Services/Graphql/Graphql'

export const ImageBox = ({ id }) => {

    const { data, error, loading } = useQuery(GQL_FILE, {
        variables: { id },
    })

    if (error) return <p>Erro ao carregar imagem</p>
    if (loading) return <p>Carregando...</p>

    const file = data.file
    return (<img src={file.url} alt={file.alt} />)
}
