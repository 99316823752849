import { Close_icon } from "../../../assets/svg";
import "./LogoutModal.scss";

import { AuthContext } from "../../shared/Auth/Auth";
import { useContext } from "react";

const LogoutModal = ({ open, onClose }) => {
  const context = useContext(AuthContext);

  const logoff = () => {
    context.logout();
  };

  if (!open) return null;
  return (
    <>
      <div className="modal_logout">
        <div className="modal_content_logout">
          <div className="modal_header_logout">
            <div className="icon" onClick={onClose}>
              {Close_icon}
            </div>
          </div>
          <div>
            <div onClick={logoff}>
              <p>Sair</p>
            </div>
          </div>
        </div>
        <div className="modal_back_logout" onClick={onClose}></div>
      </div>
    </>
  );
};

export default LogoutModal;
