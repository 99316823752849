import { useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { BrandUniverse, Enredo } from "../../../assets/svg";
import { MUTATION_CHANGEPASSWORD } from "../../shared/Services/Graphql/Graphql";

import "./ChangePassword.scss";

export default function ChangePassword() {
  const [changeUserPassword] = useMutation(MUTATION_CHANGEPASSWORD);

  let navigate = useNavigate();

  let params = useParams();

  async function changePassword() {
    const token = params.id;
    const password = (document.querySelector(".password") as HTMLInputElement)
      .value;

    changeUserPassword({
      variables: {
        data: {
          token: token,
          password: password,
        },
      },
    })
      .then((res) => {
        if (res.data) {
          navigate(`/login`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <section className="change_password">
      <div className="container">
        <div className="imagemsvg">{BrandUniverse}</div>
        <div className="message">
          <p>Cadastre uma nova senha segura.</p>
        </div>
        <form>
          <p className="message-recover-password"></p>
          <div className="field">
            <label htmlFor="password">Senha</label>
            <input
              type="password"
              className="password"
              id="password"
              name="password"
            />
          </div>
          </form>
          <form>
          <div className="field">
            <label htmlFor="confirmPassword">Confirmar Senha</label>
            <input
              type="password"
              className="confirmPassword"
              id="confirmPassword"
              name="confirmPassword"
            />
          </div>
        </form>
        <div className="align_center">
          <button type="button" onClick={changePassword}>
            ENVIAR
          </button>
        </div>
        <div className="footer-page">{Enredo}</div>
      </div>
    </section>
  );
}
