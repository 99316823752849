import { useState } from "react";
import { Plus_icon } from "../../../assets/svg";
import "./float_add.scss";
import { FloatAddBrand } from "./float_add_brand";
import { FloatAddUser } from "./float_add_user";

const FloatAddButton = () => {

  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <div className='float_add_button'>
        <div className='add_button' onClick={() => setOpen(!isOpen)}><Plus_icon /></div>
        <div className={`add_buttons ${isOpen ? 'active' : ''}`}>
          <FloatAddUser />
          <FloatAddBrand />
        </div>
      </div>
    </>
  );
};

export default FloatAddButton;
