import { useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { GQL_SECTION, MUTATION_UPDATESECTION } from "../../../shared/Services/Graphql/Graphql";

import './edit.scss';

export const EditTitleSection = ({ content, id }) => {
    const [edit, setEdit] = useState(content);
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const [updateSection] = useMutation(MUTATION_UPDATESECTION, {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GQL_SECTION, variables: { id: id } }],
    });

    useEffect(() => {
        // Verifica se o ref atual é não nulo antes de tentar acessar suas propriedades
        if (textareaRef.current) {
            const textarea = textareaRef.current;
            textarea.style.height = 'auto'; // Reseta a altura para calcular corretamente
            const scrollHeight = textarea.scrollHeight;
            textarea.style.height = scrollHeight + 'px';
        }
    }, [edit]); // Dependência para recalcular quando o texto mudar

    const handleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setEdit(e.target.value); // Atualiza o estado com o valor atual do textarea
    };

    const handleBlur = () => {
        updateSection({
            variables: {
                id: id,
                data: {
                    name: edit,
                },
            },
        })
            .then((res) => {
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <h2 className="section_name">
            <textarea
                ref={textareaRef}
                value={edit}
                onChange={handleTitleChange}
                onBlur={handleBlur}
                className="section_name_input"
                placeholder="Nome da seção"
                style={{ overflowY: 'hidden', resize: 'none' }}
            />
        </h2>
    );
};
