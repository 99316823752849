import React, { useState } from 'react'
import { GQL_SECTION, MUTATION_UPDATEDOWNLOAD } from '../../../shared/Services/Graphql/Graphql'
import { useMutation } from '@apollo/client'

export const EditDownload = ({ id, download, section }) => {

    const [infos, setInfos] = useState({
        name: '',
        link: ''
    });
    const [updateDownload] = useMutation(MUTATION_UPDATEDOWNLOAD, {
        awaitRefetchQueries: true,
        refetchQueries: [
            { query: GQL_SECTION, variables: { id: section } },
        ],
    })

    function handleDownload() {
        updateDownload({
            variables: {
                id: id,
                data: {
                    name: infos.name,
                    link: infos.link,
                }
            }
        })
            .then((res) => {
                console.log(res.data);
                setInfos({ name: '', link: '' });
            })
            .catch((err) => {
                console.log(err);
            });
    }


    return (
        <div className='downloads_edit_form'>
            <input
                type="text"
                placeholder='Nome'
                value={infos.name}
                onChange={(e) => setInfos(prev => ({ ...prev, name: e.target.value }))}
            />
            <input
                type="text"
                placeholder='Link'
                value={infos.link}
                onChange={(e) => setInfos(prev => ({ ...prev, link: e.target.value }))}
            />
            <div className='downloads_add_button' onClick={handleDownload}>
                <p>Adicionar</p>
            </div>
        </div>
    )
}
