import { useMutation } from '@apollo/client'
import {
  MUTATION_CREATESECTIONMODEL,
} from '../../../shared/Services/Graphql/Graphql'
import { Plus_icon } from '../../../../assets/svg'
import { SectionModel } from '../../../shared/interfaces/SectionModel'

import { SubmenuModel } from './submenu_model'
import MenuItem from './menu_model_item'

import './menu_model.scss'
import { useContext } from 'react'
import { ModelContext } from '../../../shared/Services/Graphql/querysContext'
import { GQL_MODEL } from '../../../shared/Services/Graphql/querys'

export const MenuModel = () => {
  const { model }: any = useContext(ModelContext)
  const sections = model?.sections;

  const [createSectionModel] = useMutation(MUTATION_CREATESECTIONMODEL, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GQL_MODEL, variables: { id: model.id } }],
  })
  function createNewSection() {
    createSectionModel({
      variables: {
        model: model.id,
        data: {
          name: 'Nova Seção'
        },
      },
    })
      .then((res) => {
        if (res.data) {
          console.log(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <ul className='menu_model_list'>
      <li className='menu_model_items'>
        {sections?.map((section: SectionModel) => (
          <div className="menu_model_item selected_item" key={section.id}>
            <MenuItem
              sectionData={section}
            ></MenuItem>
            <SubmenuModel sectionData={section} ></SubmenuModel>
          </div>
        ))}
      </li>
      <li className="menu_model_add" onClick={createNewSection}>
        <Plus_icon />
        <p> Adicionar seção</p>
      </li>
    </ul>
  )
}
