import { Link } from "react-router-dom";
import { Users_icon } from "../../../assets/svg";

export const FloatAddUser = () => {
  return (
    <>
      <div className="add_button button_brand">
        <div className="add_button_description">
          <Link
            to={{
              pathname: `/user/new`,
            }}
          >
            <span>Usuário</span>
          </Link>
        </div>
        <Link
          to={{
            pathname: `/user/new`,
          }}
        >
          {Users_icon}
        </Link>
      </div>
    </>
  );
};
