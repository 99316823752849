import { gql } from "@apollo/client";

export const MUTATION_CREATEBRANDMODEL = gql`
  mutation createBrandModel($model: ID!) {
    createBrandModel(model: $model) {
      id
    }
  }
`;

export const MUTATION_CREATEMODEL = gql`
  mutation CreateModel($idBrand: ID!, $name: String) {
    createModel(idBrand: $idBrand, name: $name) {
      id
      name
    }
  }
`;
