import React from 'react'
import { Delete_icon } from '../../../assets/svg'
import { useMutation } from '@apollo/client'
import {
  GQL_BRANDS,
  MUTATION_DELETEBRAND,
} from '../../shared/Services/Graphql/Graphql'
import { useNavigate } from 'react-router-dom'

export const Delete_brand = (props: any) => {
  const { id, redirect } = props

  const navigate = useNavigate();

  const [removebrand] = useMutation(MUTATION_DELETEBRAND, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GQL_BRANDS }],
  })

  function remove_brand() {
    removebrand({
      variables: {
        id: id,
      },
    })
      .then((res) => {
        console.log(res)
        redirect && navigate("/brands");
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className="button delete_button" onClick={() => remove_brand()}>
      <p>Excluir</p>
    </div>
  )
}
