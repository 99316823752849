import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { GQL_BRAND, MUTATION_UPDATESETTINGSBRAND } from '../../../shared/Services/Graphql/Graphql';

export const Colors = ({ idBrand, initialColors }) => {
    const [colors, setColors] = useState(initialColors);

    const [updateSettings] = useMutation(MUTATION_UPDATESETTINGSBRAND, {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GQL_BRAND, variables: { id: idBrand } }],
    });

    function handleColorChange(event) {
        const { name, value } = event.target;
        setColors({ ...colors, [name]: value });

        const updatedSettings = {
            [name]: value,
        };

        updateSettings({
            variables: {
                id: idBrand,
                data: updatedSettings
            }
        })
            .then((res) => {
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div className='color_config'>
            <h3 className='title_section'>Seções de Cor</h3>
            <div className="colorHightlight_config">
                <div className='field'>
                    <label htmlFor="colorHightlight">Cor de Destaque</label>
                    <input
                        id="colorHightlight"
                        name="colorHightlight"
                        value={colors?.colorHightlight || ''}
                        onChange={handleColorChange}
                    />
                </div>
            </div>
            <div className="colorPrimaryLogin_config">
                <div className='field'>
                    <label htmlFor="colorPrimaryLogin">Cor login - primária</label>
                    <input
                        id="colorPrimaryLogin"
                        name="colorPrimaryLogin"
                        value={colors?.colorPrimaryLogin || ''}
                        onChange={handleColorChange}
                    />
                </div>
            </div>
            <div className="colorSecondaryLogin_config">
                <div className='field'>
                    <label htmlFor="colorSecondaryLogin">Cor login - secundária</label>
                    <input
                        id="colorSecondaryLogin"
                        name="colorSecondaryLogin"
                        value={colors?.colorSecondaryLogin || ''}
                        onChange={handleColorChange}
                    />
                </div>
            </div>
        </div>
    );
}