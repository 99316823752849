import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {Alert, Snackbar} from '@mui/material/';
import "./NewUser.scss";
import { MUTATION_CREATENEWUSER } from "../../../shared/Services/Graphql/Graphql";
import { useRef, useState } from "react";
import { NivelUser } from "../../../widgets/users/nivel/nivel";

export default function NewUser() {
  const inputEmail = useRef<HTMLInputElement>(null);
  const [createUser] = useMutation(MUTATION_CREATENEWUSER);
  const navigate = useNavigate();

  function createNewUser() {
    createUser({
      variables: {
        data: {
          email: inputEmail.current?.value,
        },
      },
    })
      .then((res) => {
        if (res.data) {
          navigate(`/users`);
        }
      })
      .catch((err) => {
        if(err.graphQLErrors.length > 0)
          showErrorSnackbar(err.graphQLErrors[0].message);
      });
  }
  const showErrorSnackbar = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
  };
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  return (
    <section className='new_user'>
      <div className='container'>
        <form className='forms'>
        <div className='titulo_section'>
          <h2>Convidar novo usuário</h2>
        </div>
          <div className='container_form'>
            <div className='field'>
              <div className='email_input'>
                <input
                  type='text'
                  className='email'
                  id='email'
                  name='email'
                  ref={inputEmail}
                  placeholder="Digite o email do usuário"
                />
                <button type='button' onClick={createNewUser}>
          Enviar convite
        </button>
              </div>
            </div>
          </div>
        </form>
        <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      </div>
    </section>
  );
}
