import React from 'react'
import Types from "../../components/types";
import { ContentModule } from "../../../module/content";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  GQL_MODULE,
  MUTATION_UPDATEMODULE,
} from "../../../../shared/Services/Graphql/Graphql";
import { propDefinitions } from "../panelPropDefinition";
import InputText from "../inputsPanel";

// import './index.scss'

export const Attributes = ({ attributes, type, idblock }) => {
  const { module } = useParams();

  const attributesType = type && Object.keys(Types[type]?.attributes);

  const dataModule = ContentModule();

  const [UpdateModule] = useMutation(MUTATION_UPDATEMODULE, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GQL_MODULE, variables: { id: module } }],
  });

  function updateSection(data: any) {
    UpdateModule({
      variables: {
        id: module,
        data: {
          content: JSON.stringify(data),
        },
      },
    })
      .then(res => {
        console.log(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const handleStyleChange = (value: any, type: any) => {
    function findElementById(obj, id) {
      if (obj.id === id) {
        return [];
      }
      if (Array.isArray(obj.content)) {
        for (let i = 0; i < obj.content.length; i++) {
          const result = findElementById(obj.content[i], id);
          if (result) {
            return ["content", i, ...result];
          }
        }
      }
      for (const key in obj) {
        if (
          obj.hasOwnProperty(key) &&
          typeof obj[key] === "object" &&
          obj[key] !== null
        ) {
          const result = findElementById(obj[key], id);
          if (result) {
            return [key, ...result];
          }
        }
      }
      return null;
    }

    const path = findElementById(dataModule[0], idblock);

    const newPath =
      path && path.join(".").replace(/\.\d+/g, match => `[${match.slice(1)}]`);

    const targetObject = newPath
      ? eval(`dataModule[0].${newPath}`)
      : dataModule.find(m => m.id === idblock);

    if (idblock && type) {
      targetObject.attributes[type] = value;
    }

    updateSection(dataModule);
  };

  return (
    <div className="style_edit">
      {attributesType && <h3 className="title_panel">Atributos</h3>}
      {attributesType &&
        attributesType.map((a, index) => {
          if (Types[type]?.attributes[a].type === "Object") {
            return (
              <div className="attribute_item">
                <h4>{a}</h4>
                {Object.keys(Types[type]?.attributes[a].value).map(
                  (b, index) => {
                    return (
                      <div
                        className="style_item"
                        key={index}
                      >
                        <label>{propDefinitions[b]?.label ?? b}</label>
                        <InputText
                          key={index}
                          id={idblock}
                          value={attributes[a][b]}
                          object={attributes[a]}
                          type={a}
                          childType={b}
                          placeholder={`Enter ${b}`}
                          onBlur={handleStyleChange}
                          readOnly={false}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            );
          }

          return (
            <div className="attribute_item">
              <div
                className="style_item"
                key={index}
              >
                <label htmlFor={idblock}>
                  {propDefinitions[a]?.label ?? a}
                </label>
                <InputText
                  id={idblock}
                  value={attributes[a]}
                  type={a}
                  placeholder={`Enter ${a}`}
                  onBlur={handleStyleChange}
                  readOnly={false}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};
