import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../shared/Auth/UseAuth'
import FloatAddButton from '../../widgets/float_add/float_button_add'
import { Footer } from '../../widgets/footer'
import Topo from '../../widgets/topo'

export const ProtectedLayout = ({
  children,
  local,
}: {
  children: JSX.Element
  local: any
}) => {
  const auth = useAuth()
  const navigate = useNavigate()

  if (!auth.userId) {
    navigate('/login')
  }

  return (
    <>
      <Topo local={local} />
      <section className="content">{children}</section>
      <Footer />
      <FloatAddButton />
    </>
  )
}
