import React, { useState, useEffect } from 'react';
import { propDefinitions } from "./panelPropDefinition";

export interface InputTextProps {
  id: string;
  value: string | undefined;
  type: string;
  childType?: string;
  object?: { [key: string]: any }; // Ajuste para permitir um objeto com chaves de string e valores de qualquer tipo
  placeholder: string;
  onBlur: (value: string | { [key: string]: any }, typeInput: string) => void; // Ajuste para permitir que o primeiro argumento seja string ou objeto
  readOnly: boolean | undefined;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputText = (props: InputTextProps) => {
  const {
    id,
    value,
    type,
    childType,
    object,
    placeholder,
    onBlur,
    readOnly,
    onChange,
  } = props;
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (object && childType) {
      const updatedObject = { ...object, [childType]: e.target.value };
      onBlur(updatedObject, type);
    } else {
      onBlur(e.target.value, type);
    }
  };

  const propType = propDefinitions[childType ?? type];

  if (propType?.component === "select") {
    return (
      <select
        name={id}
        id={id}
        value={inputValue}
        onChange={handleBlur}
      >
        <option value="">Selecione um valor</option>
        {propType.options?.map((option, i) => (
          <option
            key={`${type ?? childType}-${i}-${option.value}`}
            value={option.value}
            defaultValue={value}
          >
            {option.label}
          </option>
        ))}
      </select>
    );
  }

  return (
    <input
      type={type}
      name={id}
      id={id}
      value={inputValue}
      placeholder={placeholder}
      onChange={onChange ?? (e => setInputValue(e.target.value))}
      onBlur={handleBlur}
      readOnly={readOnly}
    />
  );
};

export default InputText;
