import { useSearchParams } from 'react-router-dom'
import { UsersLoop } from '../../../widgets/users/users_loop'
import './ListUsers.scss'
import { Config_user } from '../../../widgets/users/config_user'

export default function Users() {
  const [searchParams] = useSearchParams()

  return (
    <>
      <section className="users">
        <div className="container">
          <div className="content_user">
            <div className="users">
              <UsersLoop idUrl={searchParams.get('id')} />
            </div>
            <div className="options_user">
              <Config_user idUrl={searchParams.get('id')} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
