import { useQuery } from "@apollo/client";
import qs from "query-string";

import { GQL_BRANDS } from "../../../shared/Services/Graphql/Graphql";


import "./ListBrands.scss";
import Cards_Brands from "../../../widgets/brands/cards_brands";
import { BrandModel } from "../../../shared/interfaces/BrandModel";
import Pagination from "../../../widgets/pagination/Pagination";

import { useLocation } from "react-router-dom";
import { Next_Icon, Previous_Icon } from "../../../../assets/svg";
import "./ListBrands.scss";
import { useEffect } from "react";

export default function Brands() {
  const { actualPage, setActualPage } = Pagination();

  const location = useLocation();

  const { loading, error, data, refetch } = useQuery<{
    brands: BrandModel[];
    totalBrands: number;
  }>(GQL_BRANDS, {
      variables: {
        sort: {
          update_at: "asc",
        },
        take: 12,
        skip: actualPage * 12,
      },
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    refreshList();
  }, []);

  if (loading) return <p>Loading...</p>;

  if (error) return <p>Alguma coisa está errada</p>;

  const totalPages = Math.ceil((data?.totalBrands || 1) / 12);

  function refreshList() {
    refetch();
  }

  function nextPage() {
    const queryParams = qs.parse(location.search);
    const page = queryParams.page;

    if (totalPages > Number(page) + 1) {
      return Number(page) + 1;
    }
    return Number(page);
  }

  function previousPage() {
    const queryParams = qs.parse(location.search);
    const page = queryParams.page;

    if (0 <= Number(page) - 1) {
      return Number(page) - 1;
    }
    return Number(page);
  }

  return (
    <>
      <section className='dashboard'>
        <div className='container'>
          <section className='brands dash_section'>
            <div className='titulo_section_dash'>
              <h3>Brands Recentes</h3>
            </div>
            <div className="loop_section_dash">
              {data?.brands.map((brand) => (
                <Cards_Brands brand={brand} key={brand.id} />
              ))}
            </div>
            <div className="pagination">
              <button className="next_previous" onClick={() => setActualPage(previousPage)}> {Previous_Icon} </button>
              {Array(totalPages)
                .fill("")
                .map((_, index) => {
                  return (
                    <button
                      key={index}
                      onClick={() => setActualPage(index)}
                      disabled={index === actualPage}
                    >
                      {index + 1}
                    </button>
                  );
                })}
              <button className="next_previous" onClick={() => setActualPage(nextPage)}>  {Next_Icon} </button>
            </div>
          </section>
        </div>
      </section>
    </>
  );
}
