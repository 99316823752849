import { useMutation } from '@apollo/client'
import {
  GQL_BRAND,
  MUTATION_UPDATEBRAND,
} from '../../shared/Services/Graphql/Graphql'

export const InputUpdateBrand = (props) => {
  const { id, value, type, item } = props

  const [createBrandSection] = useMutation(MUTATION_UPDATEBRAND, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GQL_BRAND, variables: { id: id } }],
  })
  function updateBrand(v) {
    const info = {
      [item]: v,
    }

    createBrandSection({
      variables: {
        id: id,
        data: info,
      },
    })
      .then((res) => {
        if (res.data) {
          console.log(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <>
      <input
        type={type}
        value={value}
        onChange={(v) => updateBrand(v.target.value)}
      />
    </>
  )
}
