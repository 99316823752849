import { useMutation, useQuery } from '@apollo/client'
import { UserModel } from '../../shared/interfaces/UserModel'
import {
  GQL_USERS,
  GQL_USERSBRAND,
  MUTATION_PUSHBRANDTOUSER,
} from '../../shared/Services/Graphql/Graphql'

export const SelectUser = (props) => {
  const { idBrand } = props

  const { loading, error, data } = useQuery<{ users: UserModel[] }>(GQL_USERS, {
    fetchPolicy: 'network-only',
  })

  const [selectBrand] = useMutation(MUTATION_PUSHBRANDTOUSER, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GQL_USERSBRAND }],
  })

  function select(value: any) {
    console.log(value)

    selectBrand({
      variables: {
        id: value,
        data: {
          brands: [idBrand],
        },
      },
    })
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  if (loading) return <p>Loading...</p>
  if (error) return <p>Erro...</p>

  return (
    <>
      <select
        onChange={(e) => {
          select(e.target.value)
        }}
      >
        {data?.users.map((user, i) => (
          <option key={i} value={user.id}>
            {user.name}
          </option>
        ))}
      </select>
    </>
  )
}
