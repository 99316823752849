import React from "react";
import {
    Square_icon,
    Columns_icon,
    Column_icon,
    Heading_icon,
    Image_icon,
    Paragraph_icon,
    SVG_icon,
    Embedded_icon,
    Box_Children_icon,
} from "../../../../assets/svg";
import Types from "./types";
import { Tooltip } from "@mui/material";

export const DefinedBlock = ({
  typeBlock = "",
  position,
  handleMoreBlockClick,
}: {
  typeBlock?: string;
  position?: string;
  handleMoreBlockClick: (
    e: React.MouseEvent<HTMLDivElement>,
    type: string
  ) => void;
}) => {
  let filterBlock = Object.entries(Types); // Default to all types if no specific typeBlock is provided

  if (typeBlock && Types[typeBlock]) {
    const parentBlock = Types[typeBlock].parent || "";
    const parentBlockType = Types[parentBlock];

    const addTypeBlock = parentBlockType
      ? parentBlockType.allowTypes
      : Types[typeBlock].allowTypes;

    filterBlock = Object.entries(Types).filter(([type, obj]: [string, any]) =>
      !(addTypeBlock && (position === "inner" || parentBlockType)) ||
      (parentBlock && position === "inner")
        ? !obj.parent
        : addTypeBlock.includes(type)
    );
  }

  return (
    <div
      className="add_types"
      style={{ gridTemplateColumns: `repeat(${filterBlock.length}, 1fr)` }}
    >
      {filterBlock.map(([type, blockData]: [string, any]) => (
        <div
          key={type}
          className="type_block"
          onClick={e => handleMoreBlockClick(e, blockData)} // Passing type instead of blockData for simplicity and consistency
        >
          {type === "box" && (
            <>
              <Tooltip title="Box">
                <div>
                  <Square_icon />
                </div>
              </Tooltip>
            </>
          )}
          {type === "box_children" && (
            <>
              <Tooltip title="Box Children">
                <div>
                  <Box_Children_icon />
                </div>
              </Tooltip>
            </>
          )}
          {type === "columns" && (
            <>
              <Tooltip title="Columns">
                <div>
                  <Columns_icon />
                </div>
              </Tooltip>
            </>
          )}
          {type === "column" && (
            <>
              <Tooltip title="Column">
                <div>
                  <Column_icon />
                </div>
              </Tooltip>
            </>
          )}
          {type === "heading" && (
            <>
              <Tooltip title="Heading">
                <div>
                  <Heading_icon />
                </div>
              </Tooltip>
            </>
          )}
          {type === "image" && (
            <>
              <Tooltip title="Image">
                <div>
                  <Image_icon />
                </div>
              </Tooltip>
            </>
          )}
          {type === "paragraph" && (
            <>
              <Tooltip title="Paragraph">
                <div>
                  <Paragraph_icon />
                </div>
              </Tooltip>
            </>
          )}
          {type === "svg" && (
            <>
              <Tooltip title="Svg">
                <div>
                  <SVG_icon />
                </div>
              </Tooltip>
            </>
          )}
          {type === "embedded" && (
            <>
              <Tooltip title="Embedded">
                <div>
                  <Embedded_icon />
                </div>
              </Tooltip>
            </>
          )}
        </div>
      ))}
    </div>
  );
};
