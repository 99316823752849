import { useMutation } from "@apollo/client";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BrandUniverse, Enredo } from "../../../assets/svg";
import { MUTATION_FORGOTPASSWORD } from "../../shared/Services/Graphql/Graphql";
import ErrorModal from "../../widgets/errorModal/ErrorModal";

import "./ForgotPassword.scss";

export default function ForgotPassword() {
  const [forgotUserPassword] = useMutation(MUTATION_FORGOTPASSWORD);
  const navigate = useNavigate();

  const [opened, setOpened] = useState(false);
  const [error, setError] = useState("");

  async function forgotPassword() {
    const email = (document.querySelector(".email") as HTMLInputElement).value;

    forgotUserPassword({
      variables: {
        email: email,
      },
    })
      .then((res) => {
        if (res.data) {
          navigate(`/forgot-password-message`);
        }
      })
      .catch((err) => {
        setOpened(true);
        setError(err.message);
      });
  }

  return (
    <>
      {opened && (
        <ErrorModal
          message={error}
          open={opened}
          onClose={() => setOpened(false)}
        />
      )}
      <section className="forgot-password">
        <div className="container">
          <div className="imagemsvg">{BrandUniverse}</div>
          <div className="message">
            <p>Insira abaixo o email de cadastro para</p>
            <p>recuperação.</p>
          </div>
          <form>
            <div className="field">
              <label htmlFor="email">E-mail</label>
              <input type="text" className="email" id="email" name="email" />
            </div>
          </form>
          <div className="align_center">
            <button type="button" onClick={forgotPassword}>
              ENVIAR
            </button>
          </div>
          <div className="footer-page">{Enredo}</div>
        </div>
      </section>
    </>
  );
}
