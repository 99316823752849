import React, { useState } from 'react';
import { Delete_icon, Image_icon } from '../../../assets/svg';
import { ModalFilesBrand } from './modal_files_brand';
import { useMutation } from '@apollo/client';
import { GQL_BRAND, MUTATION_UPDATESETTINGSBRAND } from '../../shared/Services/Graphql/Graphql';
import { ImageBox } from '../blocks/types/box_children/image_box';

interface DefinedImageType {
    id: string;
    url: string;
    name: string;
    width?: number;
    height?: number;
}

export const SelectImageHigh = ({ idBrand, logos, settings, local, title }) => {
    const [updateImage] = useMutation(MUTATION_UPDATESETTINGSBRAND, {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GQL_BRAND, variables: { id: idBrand } }],
    });

    function update_image(idFile: string, remove?: boolean) {
        const localImage = remove || idFile === '' ? { [local]: null } : { [local]: idFile };

        updateImage({
            variables: {
                id: idBrand,
                data: localImage,
            },
        })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const imageInfo = settings?.[local] ?? null;

    return (
        <>
            <div className='selectLogo_box'>
                <div className='selectLogo_config'>
                    <h4 className='selectLogo_title'>{title}</h4>
                    {imageInfo && (
                        <div className='selectLogo_remove' onClick={() => update_image(imageInfo.id, true)}>
                            <Delete_icon width='24px' />
                        </div>
                    )}
                </div>
                <select name="select_logo" id={`logo-${local}`} value={imageInfo?.id ?? ''} onChange={(e) => update_image(e.target.value)}>
                    <option value="">Selecione um logo</option>
                    {logos?.map((logo) => (
                        <option key={logo.id} value={logo.id}>{logo.type}</option>
                    ))}
                </select>
            </div>
            <div className='image_logo'>
                {imageInfo?.file?.id && <ImageBox id={imageInfo.file.id} />}
            </div>
        </>
    );
};
