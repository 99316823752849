import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { GQL_MODULE, MUTATION_UPDATEMODULE } from '../../shared/Services/Graphql/Graphql'

import './edit.scss'

export const EditTitleModule = ({ content, id }) => {
  const [edit, setEdit] = useState(content)

  useEffect(() => {
    setEdit(content)
  }, [content])

  const [updateSection] = useMutation(MUTATION_UPDATEMODULE, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GQL_MODULE, variables: { id: id } }],
  })

  const handleTitleChange = (e) => {
    updateSection({
      variables: {
        id: id,
        data: {
          name: edit,
        },
      },
    })
      .then((res) => {
        console.log(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <h3 className="module_name">
      <input type="text"
        value={edit}
        onChange={(e) => setEdit(e.target.value)}
        onBlur={handleTitleChange}
        className="edit_name_input"
        placeholder="Nome da seção"

      />
    </h3>
  )
}
