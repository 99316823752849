import React from 'react'
import { NavLink } from 'react-router-dom'

import UserAvatar from './user_avatar'
import {
  Brands_icon,
  Files_icon,
  History_icon,
  Home_icon,
  Users_icon,
} from '../../assets/svg'

import './topo.scss'

export default function Topo(props: any) {
  return (
    <header className="topo">
      <div className="local">
        <h3>{props.local ? props.local : `${props.local} ${props.name}`}</h3>
      </div>
      <div className="menu">
        <NavLink to="/dashboard">
          {Home_icon}
          <div className="point"></div>
        </NavLink>
        <NavLink to="/brands">
          {Brands_icon}
          <div className="point"></div>
        </NavLink>
        <NavLink to="/users">
          {Users_icon}
          <div className="point"></div>
        </NavLink>
        {/* <NavLink to="/history">
          {History_icon}
          <div className="point"></div>
        </NavLink> */}
        <NavLink to="/files">
          {Files_icon}
          <div className="point"></div>
        </NavLink>
      </div>
      <div className="user">
        <UserAvatar />
      </div>
    </header>
  )
}
