import { useQuery } from '@apollo/client'
import { UserModel } from '../../shared/interfaces/UserModel'
import { GQL_USERSBRAND } from '../../shared/Services/Graphql/Graphql'
import { SelectUser } from './select_user'

export const ControlerUser = (props) => {
  const { idBrand } = props

  const { loading, error, data } = useQuery<{ usersBrand: UserModel[] }>(
    GQL_USERSBRAND,
    { variables: { id: idBrand }, fetchPolicy: 'network-only' },
  )

  if (loading) return <p>Loading...</p>
  if (error) return <p>Erro...</p>

  return (
    <>
      <div className="field">
        <SelectUser idBrand={idBrand} />
      </div>
      <div className="list_users">
        <ul className='users'>
          {data?.usersBrand.map((user, i) => (
            <div className="user" key={i}>
              <li>{user.name?.charAt(0)}</li>
            </div>
          ))}
        </ul>
      </div>
    </>
  )
}
