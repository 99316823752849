import { useState } from 'react'
import { useQuery } from '@apollo/client'
import {
  GQL_BRAND
} from '../../../shared/Services/Graphql/Graphql'
import './exist_brand.scss'
import { BrandModel } from '../../../shared/interfaces/BrandModel'
import MenuBrand from '../../../widgets/brands/menu_brand/menu_brand'
import { Engine_Icon, Image_Add_icon, Image_icon, Menu_Icon } from '../../../../assets/svg'
import { Sticker } from '../../../widgets/sticker/sticker'
import { Config_brand } from '../../../widgets/brands/config_brand'
import { ImageBox } from '../../../widgets/blocks/types/box_children/image_box'
import { ModalFilesBox } from '../../../widgets/blocks/types/box_children/modal_files_box'
import { Link } from 'react-router-dom'

const ExistBrand = (brand: BrandModel) => {
  const [isLeftMenu, thisLeftMenu] = useState(true)
  const [isRightMenu, thisRightMenu] = useState(false)
  const [activeModal, setActiveModal] = useState(null);

  const toggleModal = (itemId) => {
    if (activeModal === itemId) {
      setActiveModal(null);
    } else {
      setActiveModal(itemId); // Caso contrário, abra o modal para o item clicado
    }
  };

  const { loading, error, data } = useQuery<{ brand: BrandModel }>(GQL_BRAND, {
    variables: { id: brand.id },
    fetchPolicy: 'network-only',
  })

  if (loading) return <p>Loading...</p>
  if (error) return <p>Alguma coisa está errada</p>

  return (
    <section className="brand">
      <div className="container">
        <section className="options_brand">
          <div
            className="menu"
            onClick={() => (
              thisLeftMenu(!isLeftMenu),
              isRightMenu && !isLeftMenu && thisRightMenu(false)
            )}
          >
            <Menu_Icon />
          </div>
          <div>
            <b><Link to="/brands">brands</Link></b> / <b style={{ color: "#999" }}>{data?.brand.slug}</b>
          </div>
          <div
            className="configuration_brand"
            onClick={() => (
              thisRightMenu(!isRightMenu),
              isLeftMenu && !isRightMenu && thisLeftMenu(false)
            )}
          >
            <Engine_Icon />
          </div>
        </section>
        <div className="brand">
          <div
            className="menu_brand"
            style={{
              marginLeft: isLeftMenu ? '0px' : '-360px',
            }}
          >
            <Sticker>
              <MenuBrand
                brand={data?.brand?.slug}
                sections={data?.brand?.sections}
                idBrand={data?.brand?.id}
              ></MenuBrand>
            </Sticker>
          </div>
          <div className="brand_info">
            <div className="infos">
              <header className="top-content-brand">
              </header>
              <div className="brand_container">
                <section className="information-content">
                  <div className="text-content-brand">
                    <div className="content-text-brand">
                      <h3 className="title">
                        Nossa identidade é a maior expressão da nossa marca.
                      </h3>
                      <p className="text">
                        No universo de <b>marca {data?.brand.name}</b>, você encontra tudo que você precisa saber sobre nossa marca. Utilize-a como um guia para criar uma comunicação forte e consistente.
                      </p>
                    </div>
                  </div>
                  <div className="image-content-brand">
                    <div className="items">
                      {data?.brand.highlights?.map((highlight) => (
                        <a className="item">
                          <div className="change_figure" onClick={() => toggleModal(highlight.id)}>
                            <Image_Add_icon width="24px" />
                          </div>
                          {highlight.cover?.id ? <ImageBox id={highlight.cover?.id} /> : (
                            <div className="select" onClick={() => toggleModal(highlight.id)}>
                              <Image_icon width="48px" />
                              <p>Selecionar arquivo</p>
                            </div>
                          )}
                          <p className="name" >{highlight.name}</p>
                          {activeModal === highlight.id && <ModalFilesBox setActive={() => setActiveModal(null)} id={highlight.id} title={highlight.name} />}
                        </a>
                      )) ?? []}
                      <a className="item">

                        {data?.brand?.settings?.downloadImage ?
                          <img src={data?.brand?.settings?.downloadImage?.url} alt={data?.brand?.settings?.downloadImage?.alt} /> :
                          (<img src="https://via.placeholder.com/150" alt="Imagem" />)}
                        <p className="name" >Downloads</p>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div
            className="menu_configuration"
            style={{
              marginRight: isRightMenu ? '0px' : '-360px',
            }}
          >
            <Sticker>
              <Config_brand data={data?.brand} />
            </Sticker>
          </div>
        </div>
      </div >
    </section >
  )
}

export default ExistBrand
