import React from "react";

import'./index.scss';

const SVG = ({ attributes, content, className, style }) => {

  const { iframe } = attributes;

  return React.createElement(
    "div",
    {
      className: className,
      style: style,
      dangerouslySetInnerHTML: { __html: iframe },
    },
  );
};

export default SVG;
