export type SelectOption = {
  value: string;
  label: string;
};

type PropDefinition = {
  [key: string]:
    | {
        label: string;
        component: "select";
        options: SelectOption[];
      }
    | {
        label: string;
        component: "input";
      };
};

const options: { [key: string]: SelectOption } = {
  cover: { value: "cover", label: "Cover" },
  contain: { value: "contain", label: "Contain" },
  fill: { value: "fill", label: "Fill" },
  none: { value: "none", label: "None" },
  block: { value: "block", label: "Block" },
  inline: { value: "inline", label: "Inline" },
  inlineBlock: { value: "inline-block", label: "Inline Block" },
  flex: { value: "flex", label: "Flex" },
  grid: { value: "grid", label: "Grid" },
  inlineGrid: { value: "inline-grid", label: "Inline Grid" },
  flowRoot: { value: "flow-root", label: "Flow Root" },
  contents: { value: "contents", label: "Contents" },
  center: { value: "center", label: "Center" },
  start: { value: "start", label: "Start" },
  end: { value: "end", label: "End" },
  baseline: { value: "baseline", label: "Baseline" },
  firstBaseline: { value: "first baseline", label: "First Baseline" },
  lastBaseline: { value: "last baseline", label: "Last Baseline" },
  normal: { value: "normal", label: "Normal" },
  italic: { value: "italic", label: "Italic" },
  oblique: { value: "oblique", label: "Oblique" },
  left: { value: "left", label: "Left" },
  right: { value: "right", label: "Right" },
  justify: { value: "justify", label: "Justify" },
  spaceBetween: { value: "space-between", label: "Space Between" },
  spaceAround: { value: "space-around", label: "Space Around" },
  spaceEvenly: { value: "space-evenly", label: "Space Evenly" },
  stretch: { value: "stretch", label: "Stretch" },
  unset: { value: "unset", label: "Unset" },
  inherit: { value: "inherit", label: "Inherit" },
  revert: { value: "revert", label: "Revert" },
  flexStart: { value: "flex-start", label: "Flex Start" },
  flexEnd: { value: "flex-end", label: "Flex End" },
  selfStart: { value: "self-start", label: "Self Start" },
  selfEnd: { value: "self-end", label: "Self End" },
  anchorCenter: { value: "anchor-center", label: "Anchor Center" },
  justifyAll: { value: "justify-all", label: "Justify All" },
};

export const propDefinitions: PropDefinition = {
  objectFit: {
    label: "Object Fit",
    component: "select",
    options: [options.cover, options.contain, options.fill, options.none],
  },
  display: {
    label: "Display",
    component: "select",
    options: [
      options.none,
      options.block,
      options.inline,
      options.inlineBlock,
      options.flex,
      options.grid,
      options.inlineGrid,
      options.flowRoot,
      options.contents,
    ],
  },
  justifyContent: {
    label: "Justify Content",
    component: "select",
    options: [
      options.center,
      options.start,
      options.end,
      options.flexStart,
      options.flexEnd,
      options.left,
      options.right,
      options.normal,
      options.spaceBetween,
      options.spaceAround,
      options.spaceEvenly,
      options.stretch,
    ],
  },
  alignItems: {
    label: "Align Items",
    component: "select",
    options: [
      options.center,
      options.start,
      options.end,
      options.flexStart,
      options.flexEnd,
      options.selfStart,
      options.selfEnd,
      options.normal,
      options.anchorCenter,
    ],
  },
  alignContent: {
    label: "Align Content",
    component: "select",
    options: [
      options.center,
      options.start,
      options.end,
      options.flexStart,
      options.flexEnd,
      options.baseline,
      options.firstBaseline,
      options.lastBaseline,
      options.spaceBetween,
      options.spaceAround,
      options.spaceEvenly,
      options.stretch,
    ],
  },
  fontStyle: {
    label: "Font Style",
    component: "select",
    options: [options.normal, options.italic, options.oblique],
  },
  textAlign: {
    label: "Text Align",
    component: "select",
    options: [
      options.start,
      options.end,
      options.left,
      options.right,
      options.center,
      options.justify,
      options.justifyAll,
    ],
  },
};
