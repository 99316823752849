import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { MUTATION_CREATEMODEL } from '../../shared/Services/Graphql/mutations'
import { Modal } from '../modal'
import {  GQL_MODELS } from '../../shared/Services/Graphql/querys'

export const ModelBrand = (props: any) => {
    const [open, setOpen] = useState(false)
    const [name, setName] = useState(`Modelo de ${props.name}`)

    const [createModel] = useMutation(MUTATION_CREATEMODEL, {
        refetchQueries: [{ query: GQL_MODELS }],        onCompleted: () => {
            setOpen(false)
        }
    })

    function createModelBrand() {
        createModel({
            variables: {
                idBrand: props.id,
                name: name
            }
        }).then((res) => {
            if (res.data) {
                console.log(res.data)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <>
            <div className="button button_model" onClick={() => setOpen(!open)}>
                <p>Criar modelo</p>
            </div>
            <Modal open={open} setOpen={setOpen} >
                <input
                    type="text"
                    value={name}
                    onChange={(v) => setName(v.target.value)}
                    style={{
                        padding: "0.5em 0.8em",
                        marginBottom: "1em",
                        border: "1px solid #ccc",
                    }}
                />
                <div className="button" onClick={() => createModelBrand()}> Criar modelo </div>
            </Modal>
        </>
    )
}
