import { useState } from "react";
import { useQuery } from "@apollo/client";
import "./user_avatar.scss";
import LogoutModal from "./LogoutModal/LogoutModal";
import { UserModel } from "../shared/interfaces/UserModel";
import { GQL_USER } from "../shared/Services/Graphql/Graphql";

export default function UserAvatar() {
  const { userId } = JSON.parse(localStorage.getItem("bu_login") || "{}");
  const [opened, setOpened] = useState(false);

  const { loading, error, data } = useQuery<{ user: UserModel }>(GQL_USER, {
    variables: { id: userId },
    fetchPolicy: 'network-only',
  })

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Alguma coisa está errada</p>;


  const user = data ? data.user : null;

  return (
    <>
      <div
        className="user"
        onClick={(e) => {
          setOpened(true);
        }}
      >
        <div className="nome_user">{user?.name}</div>
        <div className="avatar_user">
          {user?.profilePicture ? (
            <img className="imagem_user" src={user?.profilePicture} alt={user?.name} />
          ) : (
            <div className="nome_reduzido_user">
              <h4>{user?.name?.substring(0, 1)}</h4>
            </div>
          )}
        </div>
      </div>
      <LogoutModal open={opened} onClose={() => setOpened(false)}></LogoutModal>
    </>
  );
}
