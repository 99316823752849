import { useContext } from "react";
import { ContentModule } from "../../module/content";
import { SelectedContext } from "../../../shared/context/SelectedModule";

import { Style } from "./style";

import './panelconf.scss';
import { Attributes } from "./attributes";

export const PanelBlock = ({ dataBlock }) => {
    const { selected } = useContext(SelectedContext);
    const contentModule = ContentModule()

    let objects: { [key: string]: any }[] = [];
    function findObjectWithId(data: any[]) {
        data.forEach((i: { [key: string]: any }) => {
            i.id === dataBlock && objects.push(i);
            if (i.content && Array.isArray(i.content) && i.content.length > 0) {
                i.content.forEach(i => {
                    findObjectWithId([i]);
                });
            }
        });
        return objects;
    }
    const foundObject = ContentModule() && findObjectWithId(contentModule);

    return (
        <>
            <div className="section_title">
                <h3 className="section_name">Blocks</h3>
            </div>
            <div className="module_info">
                <div className="module_title">
                    {foundObject?.[0]?.name}
                </div>
                <div className="module_id">
                    {foundObject?.[0]?.id}
                </div>
            </div>
            <div className="section_attributes">
                <Attributes
                    attributes={foundObject?.[0]?.attributes || {}}
                    type={foundObject?.[0]?.name || ''}
                    idblock={foundObject?.[0]?.id || ''}
                />
            </div>
            <div className="section_style">
                <Style
                    styles={foundObject?.[0]?.style || {}}
                    type={foundObject?.[0]?.name || ''}
                    idblock={foundObject?.[0]?.id || ''}
                />
            </div>
        </>
    )
}