import ExistBrand from "./Edit/exist_brand";
import NewBrand from "./Create/new_brand";
import { useParams } from "react-router-dom";

const Brand = () => {
  let params = useParams();

  //@ts-ignore
  return params.id !== "new" ? <ExistBrand id={params.id} /> : <NewBrand />;
};

export default Brand;
