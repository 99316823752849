import { Plus_icon } from '../../../../assets/svg'
import { SectionModel } from '../../../shared/interfaces/SectionModel'
import './menu_brand.scss'

import { useMutation } from '@apollo/client'
import {
  GQL_BRAND,
  MUTATION_CREATESECTION,
} from '../../../shared/Services/Graphql/Graphql'

import SubmenuBrand from './submenu_brand'
import MenuItem from './menu_brand_item'

const MenuBrand = (props: any) => {
  const { sections, idBrand, brand } = props

  const [createBrandSection] = useMutation(MUTATION_CREATESECTION, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GQL_BRAND, variables: { id: idBrand } }],
  })
  function createNewSection() {
    createBrandSection({
      variables: {
        idBrand: idBrand, 
        data: {
          name: 'Nova Seção'
        },
      },
    })
      .then((res) => {
        if (res.data) {
          console.log(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <ul className='menu_brand_list'>
      <li className='menu_brand_items'>
        {sections?.map((section: SectionModel) => (
          <div className="menu_brand_item selected_item" key={section.id}>
            <MenuItem
              sectionData={section}
              slug={brand}
              fatherItem={true}
              brand={idBrand}
            ></MenuItem>
            <SubmenuBrand sectionData={section} idBrand={idBrand} slug={brand}></SubmenuBrand>
          </div>
        ))}
      </li>
      <li className="menu_brand_add" onClick={createNewSection}>
        <Plus_icon />
        <p> Adicionar seção</p>
      </li>
    </ul>
  )
}

export default MenuBrand
