import React, { createContext, useContext } from 'react';

// Definindo a interface para o contexto
interface SelectedContextType {
    selected: string;
}

// Criando o contexto com um valor padrão
export const SelectedContext = createContext<SelectedContextType>({ selected: '' });

export const SelectedModuleContext = ({ selected, children }) => {
    return (
        <SelectedContext.Provider value={{ selected }}>
            {children}
        </SelectedContext.Provider>
    );
};
