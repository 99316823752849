import { useMutation } from '@apollo/client'
import { forwardRef, useImperativeHandle } from 'react'
import { Check_icon, Edit_icon } from '../../../../assets/svg'
import {
  GQL_BRAND,
  GQL_SECTION,
  MUTATION_UPDATESECTION,
} from '../../../shared/Services/Graphql/Graphql'

export const EditSection = forwardRef((section: any, ref: any) => {
  const { id, brand, edit, editMode, ptitle } = section

  const [editSection] = useMutation(MUTATION_UPDATESECTION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      brand && { query: GQL_BRAND, variables: { id: brand } },
      { query: GQL_SECTION, variables: { id: id } },
    ],
  })

  useImperativeHandle(ref, () => ({
    getAlert(e) {
      console.log(e)
    }
  }));

  function updateSection(name: string) {
    editSection({
      variables: {
        id: id,
        data: {
          name: name,
        },
      },
    })
      .then((res) => {
        console.log(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function enableEditSection() {
    if (edit) {
      editMode(false)
      updateSection(ptitle.current?.textContent)
    } else {
      editMode(true)
    }
  }
  return (
    <div className="edit" onClick={enableEditSection}>
      <div className="editing">{Check_icon}</div>
      <div className="edited">{Edit_icon}</div>
    </div>
  )
})
