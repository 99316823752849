import { useMutation, useQuery } from '@apollo/client'
import {Alert, Snackbar, TextField} from '@mui/material/';
import {
  GQL_USER,
  GQL_USERS,
  MUTATION_DELETEUSER,
  MUTATION_FILLUSER,
  MUTATION_UPDATEUSER
} from '../../shared/Services/Graphql/Graphql'
import { UserModel } from '../../shared/interfaces/UserModel'

import './user.scss'
import { Avatar_Icon, Delete_icon, Send_Mail_icon, Edit_User_icon } from '../../../assets/svg'
import { Add_brand_user } from './add_brand_user'
import { List_brand_user } from './list_brand_user'
import InputText from '../blocks/panel/inputsPanel'
import { useState, useEffect } from 'react'

export const Config_user = (props: any) => {
  const { idUrl } = props

  const { loading, error, data } = useQuery<{ user: UserModel }>(GQL_USER, {
    variables: { id: idUrl },
    fetchPolicy: 'network-only',
  })
  const user = data?.user
  const [userInfo, setUserInfo] = useState<UserModel | null>(null)
  const [canEdit, setCanEdit] = useState(false)
  useEffect(() => {
    if (data?.user) {
      setUserInfo(data.user);
      setPassword('')
      setCanEdit(false)
    }
  }, [data?.user]);
  const [password, setPassword] = useState('')
  const [fillNewUser] = useMutation(
    MUTATION_FILLUSER, {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GQL_USERS }],
    }
  )
  const [updateUser] = useMutation(
    MUTATION_UPDATEUSER, {
      awaitRefetchQueries : true,
      refetchQueries: [{ query: GQL_USERS }],
    }
  )

  const [deleteUser] = useMutation(MUTATION_DELETEUSER, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GQL_USERS }],
  })

  const handleInputChange = (field: keyof UserModel) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    if(userInfo) {
      setUserInfo({
        ...userInfo,
        [field]:  value
      })
    }
  }
  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }
  const userDelete = () => {
    deleteUser({
      variables: {
        id: idUrl,
      },
    })
      .then((res) => {
        console.log(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const EditUser = () => {
    if(!canEdit) {
      setCanEdit(true)
      return;
    }  
    UpdateUser()
  }

  const UserActive = () => {
    if(!canEdit) {
      setCanEdit(true)
      return;
    }  
    ActiveUser()
  }

  const UpdateUser = () => {
    updateUser({
      variables: {
        id : userInfo?.id,
        data: {
          name: userInfo?.name,
          userName: userInfo?.userName,
          phone: userInfo?.phone,
          password: password,
          admin: userInfo?.admin,
        }
      },
    })
      .then(res => {
        console.log(res)
        if (res.data) {
          setUserInfo({
            ...userInfo!
          });
          setCanEdit(false)
          console.log(userInfo)
          console.log(user)
          showSuccessSnackbar('Ação concluída com sucesso!');
        }
      })
      .catch(err => {
        console.log(err)
        console.log(err.graphQLErrors)
        if(err.graphQLErrors.length > 0 && err.graphQLErrors.length < 2)
          showErrorSnackbar(err.graphQLErrors[0].message);
        else
          showErrorSnackbar('Não foi possível realizar a ação!');
      })
  }
  const ActiveUser = () => {
    fillNewUser({
      variables: {
        data: {
          token: userInfo?.token,
          name: userInfo?.name,
          userName: userInfo?.userName,
          phone: userInfo?.phone,
          password: password,
          admin: userInfo?.admin,
        }
      },
    })
      .then(res => {
        console.log(res)
        if (res.data) {
          setUserInfo({
            ...userInfo!,
            active: true
          });
          setCanEdit(false)
          showSuccessSnackbar('Ação concluída com sucesso!');
        }
      })
      .catch(err => { 
        if(err.graphQLErrors.length > 0 && err.graphQLErrors.length < 2)
          showErrorSnackbar(err.graphQLErrors[0].message);
        else
          showErrorSnackbar('Não foi possível realizar a ação!');
      })
  }
  const showSuccessSnackbar = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  };
  const showErrorSnackbar = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
  };
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <></>;

  
  return (
    <div className="conf_user">
      <div className="head_conf_user">
        <div className="avatar_user">{Avatar_Icon}</div>
        
      </div>
      <div className="infos_user">
        <div className="info_user">
          <p className="field_title">Usuário</p>
          <InputText
              id='1'
              value={canEdit ? userInfo?.userName : user?.userName}
              type='text'
              placeholder={'Apelido'}
              onBlur= {() => {}}
              readOnly={!canEdit}
              onChange={handleInputChange('userName')}
            />
        </div>
        <div className="info_user">
          <p className="field_title">Nome</p>
          <InputText
              id='2'
              value={canEdit ? userInfo?.name : user?.name}
              type='text'
              placeholder={'Nome'}
              onBlur= {() => {}}
              readOnly={!canEdit}
              onChange={handleInputChange('name')}
            />
        </div>
        <div className="info_user">
          <p className="field_title">E-mail</p>
          <InputText
              id='3'
              value={user?.email}
              type='text'
              placeholder={'E-mail'}
              onBlur= {() => {}}
              readOnly={true}
              onChange= {handleInputChange('email')}
            />
        </div>
        <div className="info_user">
          <p className="field_title">Telefone</p>
          <InputText
              id='4'
              value={canEdit ? userInfo?.phone : user?.phone}
              type='text'
              placeholder={'Telefone'}
              onBlur= {() => {}}
              readOnly={!canEdit}
              onChange= {handleInputChange('phone')}
            />
        </div>
        <div className="info_user">
          <p className="field_title">Senha</p>
          <InputText
              id='5'
              value={canEdit ? password : ''}
              type='password'
              placeholder={'Senha'}
              onBlur= {() => {}}
              readOnly={!canEdit}
              onChange= {handlePasswordChange}
            />
        </div>
        <div className="info_user">
          <p className="field_title">Administrador</p>
          <input type='checkbox'
          disabled={!canEdit}
          checked={userInfo?.admin}
          onChange={handleInputChange('admin')}></input>
        </div>
      </div>
      <div className="brands_user">
        <div className="head_brands_user">
          <h4 className="title">brands</h4>
        </div>
        <div className="content_brands_user">
          <Add_brand_user id={user?.id} />
          <List_brand_user id={user?.id} brands={user?.brands} />
        </div>
      </div>
      <div className="options_user">
        <div className="option_user">
          <div className="delete_user" onClick={() => userDelete()}>
            <Delete_icon />
          </div>
          {!userInfo?.active && (
            <div className="delete_user" onClick={UserActive}>
              {Send_Mail_icon}
            </div>
          )}
          {userInfo?.active && (
            <div className="delete_user" onClick={EditUser}>
              {Edit_User_icon}
            </div>
          )}
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  )
}
