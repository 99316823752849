import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Loop_file from './Loop_file'
import UploadFile from './Upload_file'
import Info_file from './Info_file'

import './file.scss'

export default function Files() {
  const [isSelectFile, setSelectFile] = useState({
    id: '',
    url: '',
    name: '',
    width: 0,
    height: 0,
  })

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  function selectFile({
    id,
    url,
    name,
    width,
    height,
  }) {
    isSelectFile.id != id
      ? setSelectFile({
        id: id,
        url: url,
        name: name,
        width: width,
        height: height,
      })
      : setSelectFile({ id: '', url: '', name: '', width: 0, height: 0 })
    isSelectFile.id != id ? navigate(`/files?id=${id}`) : navigate(`/files`)
  }

  return (
    <>
      <section className="dashboard">
        <div className="titulo_section_dash">
          <h3>Files</h3>
        </div>
        <div className="container">
          <section className="upload_file">
            <UploadFile />
          </section>
          <section className="content_files">
            <div className="files">
              <Loop_file
                selectFile={selectFile}
                select={searchParams.get('id')}
              />
            </div>
            <div className="information_file select_information_file">
              <Info_file searchParams={searchParams} />
            </div>
          </section>
        </div>
      </section>
    </>
  )
}
