import React, { useState } from 'react';
import Types from './types';
import Blocks from './blocks';
import { MoreBlocks } from './more_blocks';

interface BlockProps {
    name: string;
    attributes: any;
    content: any;
    className: string;
    style: any;
}

export const Block: React.FC<BlockProps> = ({ name, attributes, content, className, style }) => {
    const [options, setOptions] = useState('');
    const ToolComponent = Types[name]?.const;

    const handleMoreButtonClick = (newPosition: string) => {
        if (options === newPosition) {
            setOptions('');
        } else {
            setOptions(newPosition);
        }
    };
    
    return (
        <>
            <div className="before_more_button" onClick={() => handleMoreButtonClick('before')}>
                <MoreBlocks
                    position="before"
                    options={options}
                    typeBlock={name}
                />
            </div>
            {typeof content === "string" ? (
                <ToolComponent
                    name={name}
                    attributes={attributes}
                    content={content}
                    className={className}
                    style={style}
                />
            ) : (
                <ToolComponent
                    name={name}
                    attributes={attributes}
                    className={className}
                    style={style}
                >
                    <Blocks block={content} />
                </ToolComponent>
            )}
            <div className="after_more_button" onClick={() => handleMoreButtonClick('after')}>
                <MoreBlocks
                    position="after"
                    options={options}
                    typeBlock={name}
                />
            </div>
        </>
    );
};
