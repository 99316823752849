import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { GQL_MODULE, MUTATION_UPDATEMODULE } from '../../shared/Services/Graphql/Graphql';

import './edit.scss';

export const EditDescriptionModule = ({ content, id }) => {
  const [editing, setEditing] = useState(false);

  const [updateSection] = useMutation(MUTATION_UPDATEMODULE, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GQL_MODULE, variables: { id: id } }],
  });

  const formatContent = (htmlContent) => {
    const wrapper = document.createElement('div');
    wrapper.innerHTML = htmlContent;
  
    if (wrapper.childNodes.length === 1 && wrapper.innerHTML.trim() === '<br>' || wrapper.innerHTML.trim() === '') {
      return '<p></p>';
    }
  
    const formattedContent = Array.from(wrapper.childNodes).map(node => {
      if (node.nodeType === Node.TEXT_NODE && node.textContent?.trim()) {
        return `<p>${node.textContent}</p>`;
      } else if (node.nodeType === Node.ELEMENT_NODE && (node as Element).tagName === 'BR') {
        return ''; 
      }
      return (node as HTMLElement).outerHTML || node.textContent;
    }).join('');

    if (formattedContent.trim() === '') {
      return '<p></p>'; 
    }
  
    return formattedContent;
  };
  

  const handleBlur = (e) => {
    const rawContent = e.currentTarget.innerHTML;
    const newContent = formatContent(rawContent);

    updateSection({
      variables: {
        id: id,
        data: {
          description: newContent,
        },
      },
    })
    .then((res) => {
      console.log(res.data);
    })
    .catch((err) => {
      console.log(err);
    });

    setEditing(false);
  };

  return (
    <div
      className={`module_description ${editing ? 'editing' : ''}`}
      contentEditable={editing}
      suppressContentEditableWarning={true}
      onBlur={handleBlur}
      onClick={() => !editing && setEditing(true)}
      dangerouslySetInnerHTML={{ __html: content ||  "<p>Essa é a descrição do novo módulo</p>"}}
    />
  );
};
