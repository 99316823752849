import { useQuery } from '@apollo/client'
import { BrandModel } from '../../shared/interfaces/BrandModel'
import { GQL_BRANDS } from '../../shared/Services/Graphql/Graphql'
import Cards_Brands from './cards_brands'

export const BrandsLoop = (props: any) => {
  const { sort, take, skip, visualization } = props

  const { loading, error, data } = useQuery<{ brands: BrandModel[] }>(
    GQL_BRANDS,
    {
      variables: {
        sort: {
          update_at: sort,
        },
        take: take,
        skip: skip,
      },
    },
  )

  if (loading) return <p>Loading...</p>
  if (error) {
    console.log(error)
  }
  
  return (
    <>
      {data?.brands.map((brand) => (
        <Cards_Brands brand={brand} key={brand.id} />
      ))}
    </>
  )
}
