import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { GQL_BRANDS } from '../../shared/Services/Graphql/Graphql';
import { MUTATION_CREATEBRANDMODEL } from '../../shared/Services/Graphql/mutations';
import { ModelContext } from '../../shared/Services/Graphql/querysContext';
import { useNavigate } from 'react-router-dom';

export const ConfigModel = () => {
    const { model }: any = useContext(ModelContext);
    const navigate = useNavigate();

    const [createBrandModel] = useMutation(MUTATION_CREATEBRANDMODEL, {
        refetchQueries: [{ query: GQL_BRANDS }],
    });

    function createBrand() {
        createBrandModel({
            variables: {
                model: model.id,
            }
        }).then((res) => {
            if (res.data) {
                console.log(res.data);
                navigate("/dashboard");
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    return (
        <div className='config_model'>
            <div className='config_model_title'>
                <h3>Configurações do modelo</h3>
                <input type='text' value={model?.name} readOnly />
            </div>
            <div className='config_model_create_brand'>
                <h3>Criar Brand</h3>
                <div className='actions_model'>
                    <div className="button button_model" onClick={createBrand}>
                        <p>Criar modelo</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
