import { useNavigate } from "react-router-dom";
import { BrandUniverse, Enredo } from "../../../assets/svg";

import "./ForgotPasswordMessage.scss";

export default function ForgotPasswordMessage() {
  const navigate = useNavigate();

  function goToLogin() {
    navigate("/login");
  }
  return (
    <section className="forgot_password_message">
      <div className="container">
        <div className="imagemsvg">{BrandUniverse}</div>
        <p>
          Se o seu usuário estiver cadastrado em nosso sistema, será enviado
        </p>
        <p>um e-mail para concluir a recuperação de senha.</p>
        <div className="go_to_login">
          <a className="simple-button" onClick={goToLogin}>
            Ir para Login
          </a>
        </div>
        <div className="footer-page">{Enredo}</div>
      </div>
    </section>
  );
}
